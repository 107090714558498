//hamburger menu
function createHamburgerMenu() {
  var $win = $(window);
  var ww = window.innerWidth;
  var currentWidth = window.innerWidth;
  var breakpoint = 1024;

  // ---  横幅が変動した時に実行
  //  $win.on('load', function () {
  checkGnavWidth();
  //  });

  window.addEventListener("resize", function () {
    // ウインドウ横幅が変わっていない場合
    if (currentWidth == window.innerWidth) {
      return false;
    }
    checkGnavWidth();
    currentWidth = window.innerWidth; // 横幅を更新
  });

  // -- toggle action
  $("#toggle").on("click", function () {
    $(this).toggleClass("is-active");
    $("html").toggleClass("open");
    let menuBg = $('<div data-js="closeMenu" class="l-navigation__bg">');

    if ($(this).hasClass("is-active")) {
      $("#gnavi").addClass("is-active");
      $(".l-header").addClass("is-active");
      if ($('[data-js="closeMenu"]').length === 0) {
        $("#gnavi").append(menuBg);
      }
      $('[data-js="closeMenu"]').fadeIn();
    } else {
      $("#gnavi").removeClass("is-active");
      $(".l-header").removeClass("is-active");
      $('[data-js="closeMenu"]').fadeOut();
    }
    $('[data-js="closeMenu"]').on("click", function () {
      $("#toggle").removeClass("is-active");
      $("html").removeClass("open");
      $("#gnavi").removeClass("is-active");
      $(".l-header").removeClass("is-active");
      $(this).fadeOut();
    });

    // $("#gnavi").slideToggle(function () {
    //   // iOSバグ対策
    //   if ($(this).hasClass("is-active")) {
    //     $(this).css("display", "block");
    //   } else {
    //     //監視解除
    //     $(this).css("display", "none");
    //   }
    // });
    // iOSバグ対策

    //スマホでスクロールした際に上下のバーが出てきて、高さが変わるのに対応する。
    if (ww <= breakpoint) {
      var windowInnerHeight = window.innerHeight;
      var headerHeight = 61; //variable.scssの「$headerHeight-sp」と同じ値に。
      $(".l-navigation__inner").css("height", windowInnerHeight - headerHeight);
      $('[data-js="closeMenu"]').css(
        "height",
        windowInnerHeight - headerHeight
      );
    }
  });

  function checkGnavWidth() {
    ww = window.innerWidth;
    $("[data-js='dropDown']").off();
    if (ww > breakpoint) {
      // $("#gnavi").css("display", "block");
      // $(".l-navigation__inner").css("height", "auto");
    } else {
      $('[data-js="closeMenu"]').remove();
      // $("#gnavi").css("display", "none");
    }
    globalNavInit();
  }

  // --- リサイズ時の初期化
  function globalNavInit() {
    $("[data-js='dropDown']").on("click", function () {
      $(this).toggleClass("is-opened");
    });
  }
}
